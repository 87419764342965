<template>
  <div class="home">
    <div class="image">
      <van-image
        width="100px"
        style="padding: 20px"
        height="100px"
        fit="contain"
        src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_success.png"
      />
    </div>
  </div>
  <div class="btn-card">
    <h3 class="title">业务办理</h3>
    <div class="row">
      <div class="col" @click="handleGoIsda">
        <svg
          t="1703128007724"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="10529"
          width="200"
          height="200"
        >
          <path
            d="M768 326.4c-70.4 0-134.4-57.6-134.4-134.4V89.6c0-6.4-6.4-19.2-19.2-19.2h-384c-51.2 0-89.6 38.4-89.6 89.6v697.6c0 51.2 38.4 89.6 89.6 89.6h556.8c51.2 0 89.6-38.4 89.6-89.6V339.2c0-6.4-6.4-19.2-19.2-19.2H768z m-19.2 467.2H275.2c-6.4 0-19.2-6.4-19.2-19.2v-12.8c0-6.4 6.4-19.2 19.2-19.2h467.2c6.4 0 19.2 6.4 19.2 19.2v12.8c0 12.8-6.4 19.2-12.8 19.2z m0-153.6H275.2c-6.4 0-19.2-6.4-19.2-19.2v-12.8c0-6.4 6.4-19.2 19.2-19.2h467.2c6.4 0 19.2 6.4 19.2 19.2v12.8c0 12.8-6.4 19.2-12.8 19.2z m0-153.6H275.2c-6.4 0-19.2-6.4-19.2-19.2v-6.4c0-6.4 6.4-19.2 19.2-19.2h467.2c6.4 0 19.2 6.4 19.2 19.2v12.8c0 6.4-6.4 12.8-12.8 12.8z m12.8 0"
            p-id="10530"
            fill="#3389F7"
          ></path>
          <path
            d="M678.4 128v57.6c0 51.2 38.4 89.6 89.6 89.6h57.6c19.2 0 32-25.6 12.8-38.4l-128-128c-6.4-12.8-32 0-32 19.2z m0-51.2"
            p-id="10531"
            fill="#3389F7"
          ></path>
        </svg>
        TRS收益互换衍生品服务
      </div>
      <div class="col" @click="handleGoSubAccount">
        <svg
          t="1703127974359"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="9472"
          width="200"
          height="200"
        >
          <path
            d="M790.4 707.2l233.6 3.2c0-121.6-60.8-294.4-211.2-294.4-83.2 0-128 41.6-163.2 102.4 70.4 32 112 112 140.8 188.8z m22.4-604.8c-41.6 0-83.2 19.2-108.8 44.8 28.8 35.2 44.8 80 44.8 131.2 0 35.2-9.6 70.4-25.6 99.2 25.6 19.2 57.6 28.8 89.6 28.8 83.2 0 153.6-67.2 153.6-153.6-3.2-83.2-70.4-150.4-153.6-150.4zM233.6 707.2H0c0-121.6 60.8-294.4 211.2-294.4 83.2 0 128 41.6 163.2 102.4-70.4 35.2-112 115.2-140.8 192z m-22.4-608c41.6 0 83.2 19.2 108.8 44.8-25.6 38.4-41.6 83.2-41.6 134.4 0 35.2 9.6 70.4 25.6 99.2-25.6 19.2-57.6 28.8-89.6 28.8-83.2 0-153.6-67.2-153.6-153.6 0-83.2 67.2-153.6 150.4-153.6z m0 0"
            fill="#3389F7"
            p-id="9473"
          ></path>
          <path
            d="M220.8 905.6c0-169.6 76.8-406.4 288-406.4 214.4 0 297.6 240 297.6 409.6"
            fill="#3389F7"
            p-id="9474"
          ></path>
          <path
            d="M297.6 278.4a214.4 214.4 0 1 0 428.8 0 214.4 214.4 0 0 0-428.8 0z m0 0"
            fill="#3389F7"
            p-id="9475"
          ></path>
        </svg>
        开立子账户
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage, Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  data() {
    return {};
  },
  methods: {
    handleGoIsda() {
      if (this.isdaState === 0) {
        this.$router.push({ path: "/isda/aggrement" });
      } else {
        this.$router.push({ path: "/isda/search" });
      }
    },
    handleGoSubAccount() {
      // 0-未申请 1-已申请 2-审批中 3-审批通过 4-拒绝
      if (this.subAccountState === 0 || this.subAccountState === 3) {
        this.$router.push({ path: "/isda/openSubAccount" });
      } else {
        this.$router.push({ path: "/isda/openSubAccount/search" });
      }
    },
  },
  setup() {
    const isdaState = ref();
    const subAccountState = ref();
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "业务办理");
      store.commit("setStepsShow", false);
      store.commit("setTitleLeft", true);
      const isdaStateRes = await httpApi.getIsdaState();
      isdaState.value = isdaStateRes.data.isda_state;
      const subAccountStateRes = await httpApi.getSubAccountState();
      subAccountState.value = subAccountStateRes.data.subAccountState;
    });
    return { isdaState, subAccountState };
  },
};
</script>

<style lang="less">
.home {
  padding-left: @space-0;
  padding-right: @space-0;
}
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-card {
  margin-top: 20px;
  text-align: left;
  .title {
    background: white;
    border-bottom: 4px solid #f6f6f6;
    padding: 5px;
    margin: 0px;
  }
  .row {
    display: flex;
    flex-direction: column;
    background: white;
    border-bottom: 4px solid #f6f6f6;
    .col {
      flex: 1;
      padding: 6px 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .col:first-child {
      border-bottom: 4px solid #f6f6f6;
    }
  }
}
</style>
